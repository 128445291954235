<template>
  <div>
    <component-layout title="Edit Payment">
      <v-form ref="form" lazy-validation>
        <div class="row">
          <div class="col-md-6">
            <v-text-field
              label="Invoice Ref No."
              v-model="form.invoice_ref_no"
              v-bind="basic"
              disabled
              outlined
            ></v-text-field>
          </div>
          <div class="col-md-6">
            <v-text-field
              label="Due Amount"
              v-model="form.due_amount"
              v-bind="basic"
              disabled
              outlined
            ></v-text-field>
          </div>
          <div class="col-md-6">
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="form.paid_date"
                  clearable
                  label="Date"
                  readonly
                  v-bind="{ ...basic, ...attrs }"
                  v-on="on"
                  @click:clear="form.paid_date = null"
                  :rules="[rules.required]"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.paid_date"
                @change="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="col-md-6">
            <v-text-field
              label="Payment Amount"
              v-model="form.paid_amount"
              v-bind="basic"
              :rules="[rules.required]"
              @keyup="checkRemainingAmount"
              outlined
            ></v-text-field>
          </div>
          <div class="col-md-6">
            <v-text-field
              label="Remaining Amount"
              v-model="form.remaining_amount"
              v-bind="basic"
              disabled
              outlined
            ></v-text-field>
          </div>
          <div class="col-md-6">
            <v-select
              :items="payment_methods"
              label="Payment Methods"
              v-model="form.payment_method"
              v-bind="basic"
              :rules="[rules.required]"
              outlined
            ></v-select>
          </div>
          <div class="col-md-6">
            <v-text-field
              label="Paid By"
              v-model="form.paid_by"
              :rules="[rules.required]"
              v-bind="basic"
              outlined
            ></v-text-field>
          </div>
          <div class="col-md-6">
            <v-text-field
              label="Received By"
              v-model="form.recipient "
              :rules="[rules.required]"
              v-bind="basic"
              outlined
            ></v-text-field>
          </div>
          <!-- <div class="col-md-6">
            <div>
              <input type="file" @change="uploadFile" ref="file" />
              <button type="button" class="btn px-3 py-2 primary-bg" @click="submitFile">
                Upload!
              </button>
            </div>
          </div> -->
          <div class="col-md-12">
            <v-textarea
              outlined
              v-model="form.remarks"
              name="input-7-4"
              v-bind="basic"
              label="Remarks"
              value=""
            ></v-textarea>
          </div>
          <div class="col-md-12">
            <v-btn
              color="#1d2b58"
              min-width="90px"
              class="white--text"
              @click="updatePayment()"
              :loading="loading"
              >Submit</v-btn
            >
            <v-btn
              color="#d1d1d1 "
              min-width="90px"
              @click="viewPayment()"
              class="pull-right"
              >Cancl</v-btn
            >
            <v-spacer></v-spacer>
          </div>
        </div>
      </v-form>
    </component-layout>
  </div>
</template>

<script>
import util from "@/util/util";
import componentLayout from "@/components/component-layout.vue";
export default {
  components: { componentLayout },
  data() {
    return {
      loading: false,
      rules: util.validation,
      basic: util.input.basic,
      form: {},
      payment_methods: ['Cash','Bank Deposit', 'Cheque', 'Wire Transfer'],
      menu1: false,
      menu2: false,
      modal: false,
      isValid: false,
      images: null,
    };
  },
  mounted() {
      this.getPaymentData();
  },
  methods: {
    getPaymentData() {
      util
        .http({ url: "/payment/" + this.$route.params.id, method: "get" })
        .then((res) => {
            console.log(res);
          this.form = {
            invoice_id: res.data.data.invoice_id,
            invoice_ref_no: res.data.data.invoice.ref_no,
            due_amount: res.data.data.invoice.due_amount + res.data.data.paid_amount,
            paid_amount: res.data.data.paid_amount,
            remaining_amount: res.data.data.invoice.due_amount,
            payment_method: res.data.data.payment_method,
            paid_by: res.data.data.paid_by,
            recipient : res.data.data.recipient,
            remarks: res.data.data.remarks,
            paid_date: res.data.data.paid_date,
          };
        });
    },
    uploadFile() {
      this.Images = this.$refs.file.files[0];
    },
    submitFile() {
      const formData = new FormData();
      formData.append("file", this.Images);
      const headers = { "Content-Type": "multipart/form-data" };
      util
        .http({
          url: "/payment/file-upload", formData, headers, method: "post"
        }).then((res) => {
          console.log(res.data);
        });
    },
    checkRemainingAmount() {
      if (this.form.paid_amount > this.form.due_amount) {
        this.form.paid_amount = 0;
      }
      let rm = this.form.due_amount - this.form.paid_amount;
      this.form.remaining_amount = rm.toFixed(2);
    },
    updatePayment() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        util
          .http({ url: "payment/" + this.$route.params.id, method: "patch", data: this.form })
          .then((res) => {
            this.loading = false;
            if (res.data.status == 1) {
              util.notify(1, "Payment Updated");
              this.data = {};
              this.$router.push({
                name: "invoice.payment.index",
                params: { id: res.data.data.invoice_id },
              });
            } else {
              util.notify(0, res.form.errors[0]);
            }
          });
      }
    },
    viewPayment(){
      this.$router.push({
        name: "invoice.payment.index",
        params: { id: this.form.invoice_id },
      });
    }
  },
};
</script>
